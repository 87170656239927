import { OktaAuth } from '@okta/okta-auth-js';
import { AuthType } from './utils/constants';

const staffOidc = {
  clientId: process.env.VUE_APP_OICD_CLIENT_ID,
  issuerBase: process.env.VUE_APP_OICD_OKTA_DOMAIN,
};

const externalOidc = {
  clientId: process.env.VUE_APP_OICD_EXT_CLIENT_ID,
  issuerBase: process.env.VUE_APP_OICD_EXT_OKTA_DOMAIN,
};

export function getAuthTypeFromURL() {
  const authTypeParam = new URLSearchParams(window.location.search).get('authType');

  if (authTypeParam !== null) {
    const authType = authTypeParam.toLowerCase();
    localStorage.setItem('authType', authType);
    return authType;
  }

  return localStorage.getItem('authType');
}

function getOktaAuth(authType) {
  const validAuthTypes = [AuthType.STAFF, AuthType.USER];
  if (!validAuthTypes.includes(authType)) return null;

  const baseOidc = authType === AuthType.STAFF ? staffOidc : externalOidc;

  return new OktaAuth({
    ...baseOidc,
    issuer: `https://${baseOidc.issuerBase}`,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
  });
}

export default getOktaAuth;
