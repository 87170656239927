import Vue from 'vue';
import VueRouter from 'vue-router';
import OktaVue from '@okta/okta-vue';
import { LoginCallback } from '@okta/okta-vue';
import HomePage from "@/components/HomePage";
import getOktaAuth, { getAuthTypeFromURL } from '@/oktaConfig';

Vue.use(VueRouter);

const authType = getAuthTypeFromURL();
const oktaAuth = getOktaAuth(authType);
if (oktaAuth) {
  Vue.use(OktaVue, { oktaAuth });
}

const routes = [
    {
        path: '/',
        component: HomePage,
        props: (route) => (
            {
                productSKU: route.query.productSKU,
                price: Number(route.query.price),
                financingProductSKU: route.query.financingProductSKU,
                financingPrice: Number(route.query.financingPrice),
                currencyCode: route.query.currencyCode,
                countryCode: route.query.countryCode,
                region: route.query.region,
                targetDomain: route.query.targetDomain,
                paymentBrokerAccountId: route.query.paymentBrokerAccountId,
                promoCode: route.query.promoCode,
                promoAmount: Number(route.query.promoAmount),
                threeDSEnabled: Boolean(route.query.threeDSEnabled),
                merchantInitiated: Boolean(route.query.threeDSEnabled),
                discountedMonthlyPayment: Number(route.query.discountedMonthlyPayment),
            }),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login/callback',
        component: LoginCallback,
    },

];

const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;
