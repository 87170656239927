<template>
  <div id="app">
    <div class="container mt-20" style="max-width: 800px; padding-bottom: 50px">
      <payment-option-step
        v-show="showPaymentOptions"
        :currencyCode="currencyCode"
        :countryCode="countryCode"
        :locale="locale"
        :product="product"
        :paymentPlan="paymentPlan"
      />
      <payment-step
        v-show="step === 'PAYMENT'"
        @back="updateStep('OPTION')"
        @express-payment-method-success="onExpressPaymentMethodSuccess"
        @express-payment-created="onExpressPaymentSuccess"
        @next="goToReviewStep"
        :paymentPlan="paymentPlan"
        :paymentMethod="paymentMethod"
        :currencyCode="currencyCode"
        :countryCode="countryCode"
        :authClient="authClient"
        :locale="locale"
        :threeDSEnabled="threeDSEnabled"
        :eventBus="eventBus"
        :paymentBrokerAccountId="paymentBrokerAccountId"
        :isNewPaymentMethodSelected="isNewPaymentMethodSelected"
        :promoCode="promoCode"
        :promoAmount="promoAmount"
        :discountedMonthlyPayment="discountedMonthlyPayment"
      />
    </div>
    <review-step
      v-if="step === 'REVIEW'"
      @back="updateStep('PAYMENT')"
      @next="goToSummaryStep"
      :paymentPlan="paymentPlan"
      :paymentMethod="paymentMethod"
      :currencyCode="currencyCode"
      :countryCode="countryCode"
      :locale="locale"
      :isExpressPayAuthorized="isExpressPayAuthorized"
      :eventBus="eventBus"
      :address="address"
      :applicationId="applicationId"
      :fsClient="fsClient"
      :promoCode="promoCode"
      :promoAmount="promoAmount"
      :discountedMonthlyPayment="discountedMonthlyPayment"
    />
    <summary-step v-if="step === 'SUMMARY'" :payment="payment" />
  </div>
</template>

<script>
import PaymentStep from "./PaymentStep.vue";
import ReviewStep from "./ReviewStep.vue";
import SummaryStep from "./SummaryStep.vue";
import PaymentOptionStep from "./PaymentOptionStep.vue";
import Vue from "vue";
import {
  FinanceClient,
  OktaAuthClient,
  GuestAuthClient,
} from '@smiledirectclub/sdc.pfi-core-external';

export default {
  name: "HomePage",
  components: {
    PaymentOptionStep,
    PaymentStep,
    ReviewStep,
    SummaryStep,
  },
  props: {
    paymentBrokerAccountId: { type: String, required: true },
    countryCode: { required: false, type: String, default: "US" },
    currencyCode: { required: false, type: String, default: "USD" },
    promoCode: { required: false, type: String },
    promoAmount: { required: false, type: Number },
    discountedMonthlyPayment: { required: false, type: Number },
    threeDSEnabled: { required: false, type: Boolean, default: false},
    merchantInitiated: { required: false, type: Boolean, default: false},
  },
  data() {
    return {
      step: "OPTION",
      locale: "en-AU",
      product: {
        productSKU: "AS0001",
        financingSKU: "AS0002",
        price: 296500,
        financingPrice: 296500,
      },
      email: 'cerberusson@fake.com',
      phone: '0353594017',
      creditProductId: 'a808ef26-b188-4525-83fc-4eba4a9e58bd',
      paymentPlan: null,
      paymentMethod: null,
      payment: null,
      isExpressPayAuthorized: false,
      isNewPaymentMethodSelected: false,
      applicationId: null,
      loanAgreement: null,
      eventBus: new Vue(),
      fsClient: null,
      address: {},
      authClient: this.$auth
        ? new OktaAuthClient({ auth: this.$auth })
        : new GuestAuthClient({ url: process.env.VUE_APP_GUEST_AUTH_URL })
    };
  },
  computed: {
    buildSmilePayURL() {
      return `https://smilepay-${process.env.VUE_APP_ENVIRONMENT}.smiledirectclub.com/?productSKU=${this.productSKU}&financingProductSKU=${this.financingProductSKU}&price=${this.price}&financingPrice=${this.financingPrice}&country=${this.countryCode}&currency=${this.currencyCode}&targetDomain=${window.location.href}&defaultOption=${this.selectedOption}&defaultSubOption=${this.selectedSubOption}`;
    },
    useIFrame() {
      return process.env.VUE_APP_USE_IFRAME === "true";
    },
    environment() {
      return process.env.VUE_APP_ENVIRONMENT;
    },
    targetDomain() {
      return window.location.href;
    },
    showPaymentOptions() {
      return this.step === "OPTION" || this.step === "PAYMENT";
    },
    smilePaySelected() {
      return this.paymentPlan && this.paymentPlan.financingProvider;
    },
  },
  mounted() {
    this.eventBus.$on(
      "payment-method-change-requested",
      this.unselectPaymentMethod
    );
    window.addEventListener("message", this.handleEvent, false);
   
    this.fsClient = new FinanceClient(process.env.VUE_APP_ENVIRONMENT, this.authClient);
  },
  destroyed() {
    window.removeEventListener("message", this.handleEvent, false);
  },
  methods: {
    clearSelectedPaymentMethodData() {
      this.isExpressPayAuthorized = false;
      this.paymentMethod = null;
    },
    unselectPaymentMethod(event) {
      this.clearSelectedPaymentMethodData();
      if (event.notifyComponents) {
        window.postMessage({ eventType: "wallet-ui-unselect-payment-method" });
        window.dispatchEvent(
          new CustomEvent("express-pay-unselect-payment-method")
        );
      }
      this.goToPaymentStep();
    },
    handleEvent(event) {
      if (
        event &&
        event.data &&
        event.data.eventType === "SelectedPaymentPlanEvent"
      ) {
        this.paymentPlan = event.data;
        this.paymentPlan.totalPayment = Math.floor(
          this.paymentPlan.totalPayment
        );
        this.step = "PAYMENT";
      }
      if (
        event &&
        event.data &&
        event.data.eventType === "wallet-ui-payment-success"
      ) {
        this.onPaymentSuccess(event.data);
      }
      if (
        event &&
        event.data &&
        event.data.eventType === "wallet-ui-payment-failure"
      ) {
        this.onPaymentFailure(event.data);
      }
      if (
        event &&
        event.data &&
        event.data.eventType === "wallet-ui-payment-method-change"
      ) {
        this.onPaymentMethodChange(event.data);
      }
      // only for demo purposes, not used.
      if (
        event &&
        event.data &&
        event.data.eventType === "wallet-ui-payment-method-success"
      ) {
        this.onPaymentMethodSuccess(event.data);
      }
      // only for demo purposes, not used.
      if (
        event &&
        event.data &&
        event.data.eventType === "wallet-ui-payment-method-failure"
      ) {
        this.onPaymentMethodFailure(event.data);
      }
      if (
        event &&
        event.data &&
        event.data.eventType === "wallet-ui-selected-payment-method-change"
      ) {
        this.onSelectedPaymentMethodChange(event.data);
      }
    },
    onPaymentMethodSuccess(event) {
      console.log("Payment Method Created:", event.paymentMethodId);
    },
    onPaymentMethodFailure(event) {
      console.log("Payment Method Failed:", event.errorMessage);
      console.log("Payment Method Failed statusCode:", event.statusCode);
      console.log("Payment Method Failed errorCode:", event.errorCode);
    },
    onPaymentFailure(event) {
      console.log("Payment Failed:", event.errorMessage);
      console.log("Payment Failed statusCode:", event.statusCode);
      console.log("Payment Failed errorCode:", event.errorCode);
    },
    onPaymentMethodChange(eventData) {
      if (this.isExpressPayAuthorized && !eventData.isPaymentMethodSelected) {
        console.log(
          "Remain Authorized Express Payment Method",
          this.paymentMethod
        );
        return;
      }
      console.log("Payment Method Changed:", eventData);
      this.clearSelectedPaymentMethodData();
      this.paymentMethod = eventData;
    },
    onSelectedPaymentMethodChange(eventData) {
      this.isNewPaymentMethodSelected = eventData.isNewPaymentMethod;
    },
    onExpressPaymentSuccess(eventData) {
      console.log("Express Payment Success:", eventData);
      this.payment = eventData;
    },
    onExpressPaymentMethodSuccess(eventData) {
      console.log("Payment Method Changed:", eventData);
      this.paymentMethod = eventData;
      this.isExpressPayAuthorized = true;
    },
    onPaymentSuccess(eventData) {
      console.log("Payment Success:", eventData);
      this.payment = eventData;
    },
    goToPaymentStep() {
      this.step = "PAYMENT";
    },
    goToReviewStep(eventData) {
      this.address = eventData;
      this.step = "REVIEW";
    },
    goToSummaryStep() {
      this.step = "SUMMARY";
    },
    updateStep(step) {
      this.step = step;
    },
    collectApplicationInfo() {
      const names = this.address.name.split(' ');
      const firstName = names[0];
      const lastName = names.length > 1 ? names[1] : '';

      const address = {
        'street_1': this.address.address1,
        'street_2': this.address.address2,
        'locality': this.address.city,
        'region': this.address.region,
        'postal_code': this.address.postalCode,
        'country_code': this.countryCode,
      };
      return {
        'first_name': firstName,
        'last_name': lastName,
        'email': this.email,
        'shipping_address': { ...address },
        'billing_address': { ...address },
        'phone': this.phone,
        'patient_first_name': firstName,
        'patient_last_name': lastName,
        'customer_id': this.paymentBrokerAccountId,
        'amount': (this.paymentPlan.amount-this.paymentPlan.downPayment)/100,
        'months': this.paymentPlan.months,
        'down_payment': this.paymentPlan.downPayment/100,
        'business_entity': this.paymentPlan.businessEntity,
        'culture_code': this.locale,
      }
    },
    async createLoanApplication() {
      const payload = this.collectApplicationInfo();
      payload.product_id = this.creditProductId;
      const response = await this.fsClient.createApplication(payload);
      return response.application_id;
    },
    async updateLoanApplication() {
      const payload = this.collectApplicationInfo();
      await this.fsClient.updateApplication(this.applicationId, payload);
    },
    async createLoanAgreement() {
      return this.fsClient.createAgreement(this.applicationId);
    },
    async esignLoanAgreement() {
      await this.fsClient.esign(this.applicationId);
    },
  },
  watch: {
    async step(newStep) {
      if (newStep !== 'REVIEW' || !this.smilePaySelected) {
        return;
      }

      try {
        if (!this.applicationId) {
          this.applicationId = await this.createLoanApplication();
        } else {
          await this.updateLoanApplication();
        }

        if (!this.loanAgreement) {
          this.loanAgreement = await this.createLoanAgreement();
          await this.esignLoanAgreement();
        }
      } catch (error) {
        throw new Error(`Error during loan application process: ${error.message}`);
      }
    }
  }
};
</script>

<style></style>
