var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", staticStyle: { "margin-top": "20px" } },
    [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column is-6" },
          [
            _c("smile-media-card", {
              staticStyle: { "margin-bottom": "10px" },
              scopedSlots: _vm._u([
                {
                  key: "card-content",
                  fn: function () {
                    return [
                      _c(
                        "h4",
                        {
                          staticClass: "title is-5 has-text-left",
                          attrs: { id: "contact-label" },
                        },
                        [_vm._v("Contact")]
                      ),
                      _c("p", [_vm._v(_vm._s(_vm.address.name))]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("smile-media-card", {
              staticStyle: { "margin-bottom": "10px" },
              scopedSlots: _vm._u([
                {
                  key: "card-content",
                  fn: function () {
                    return [
                      _c(
                        "h4",
                        {
                          staticClass: "title is-5 has-text-left",
                          attrs: { id: "shipping-label" },
                        },
                        [_vm._v(" Shipping address ")]
                      ),
                      _c("p", [_vm._v(_vm._s(_vm.formatAddress))]),
                      _c(
                        "smile-field",
                        {
                          staticStyle: { "font-weight": "bold" },
                          attrs: {
                            id: "shipping-change-link-field",
                            "data-orion-id": "shipping-change-link-field",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                id: "shipping-change-link",
                                "data-orion-id": "shipping-change-link",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("back")
                                },
                              },
                            },
                            [_vm._v(" Change ")]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("smile-media-card", {
              staticStyle: { "margin-bottom": "10px" },
              scopedSlots: _vm._u([
                {
                  key: "card-content",
                  fn: function () {
                    return [
                      _c(
                        "h4",
                        {
                          staticClass: "title is-5 has-text-left mb-3",
                          attrs: { id: "wallet-ui-payment-label" },
                        },
                        [_vm._v(" Payment ")]
                      ),
                      _c("payment-method-ready", {
                        attrs: {
                          "payment-method": _vm.paymentMethod,
                          "is-express-pay-authorized":
                            _vm.isExpressPayAuthorized,
                          "event-bus": _vm.eventBus,
                        },
                      }),
                      _c(
                        "h4",
                        {
                          staticClass: "title is-5 has-text-left",
                          staticStyle: { "margin-top": "25px" },
                          attrs: { id: "wallet-ui-billing-label" },
                        },
                        [_vm._v(" Billing ")]
                      ),
                      _c("p", [_vm._v("Billing address same as shipping")]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm.isSmilePay
              ? _c(
                  "button",
                  {
                    staticClass:
                      "button is-primary is-rounded is-fullwidth mt-6",
                    attrs: { disabled: _vm.isSubmitting },
                    on: { click: _vm.downloadAgreement },
                  },
                  [_vm._v(" Download Finance Agreement ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-6" },
          [
            _vm.isSmilePay
              ? _c("sub-smilepay", {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: {
                    months: 24,
                    productSKU: "sdhjdskjds",
                    "monthly-payment": _vm.monthlyPayment,
                    "down-payment": _vm.downPayment,
                    currency: _vm.currencyCode,
                    country: _vm.countryCode,
                    promoCode: _vm.promoCode,
                    promoAmount: _vm.promoAmount,
                    discountedMonthlyPayment: _vm.discountedMonthlyPayment,
                  },
                })
              : _vm._e(),
            !!_vm.paymentPlan
              ? _c("order-summary", {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { "payment-plan": _vm.paymentPlan },
                })
              : _vm._e(),
            _vm.showErrorMessage
              ? _c("div", { staticClass: "mt-8" }, [
                  _c("article", { staticClass: "message is-danger" }, [
                    _c(
                      "div",
                      { staticClass: "message-body" },
                      [
                        _c("smile-icon", {
                          attrs: {
                            icon: "alert-triangle",
                            size: "is-medium",
                            type: "is-danger",
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            " There was a problem with your payment. You have not been charged. Please try again with a different card or payment method."
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button is-primary is-rounded is-fullwidth mb-6",
                attrs: { disabled: _vm.isSubmitting },
                on: {
                  click: function ($event) {
                    return _vm.$emit("back")
                  },
                },
              },
              [_vm._v(" Back to Selection ")]
            ),
            _c(
              "button",
              {
                staticClass: "button is-primary is-rounded is-fullwidth mb-6",
                attrs: { disabled: _vm.isSubmitting },
                on: { click: _vm.placeOrder },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.isSubmitting ? "Processing..." : "Purchase") +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }