import Vue from 'vue'
import App from './App.vue'
import ComponentLibrary from "sdc-component-library";

import 'sdc-component-library/dist/sdc.css';
import router from "@/router";

Vue.config.productionTip = false
Vue.use(ComponentLibrary);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
